<template>
	<!-- 产品-模块维护 -->
	<GModule class="container">
		<GTitle name="模块维护" style="margin: 20px;"></GTitle>
		<div class="add-box">
			<div class="add">
				<a-breadcrumb separator=">" v-if="menuUrl.length > 0">
				    <a-breadcrumb-item v-for="(itm,ind) in menuUrl" :key="ind">{{itm.name}}</a-breadcrumb-item>
				 </a-breadcrumb>
				 <div class="child" v-for="(item,index) in menuList" :key="index">
					<a-input v-model:value="item.name"/>
					<a-button class="btn clo3" @click="submit(item)">保存</a-button>
					<a-popconfirm
						title="确认是否删除?"
						ok-text="确认"
						cancel-text="取消"
						@confirm="confirm(index)"
						@cancel="cancel">
						<a-button type="danger" class="btn">删除</a-button>
					  </a-popconfirm>
				 </div>
				 <div class="child" v-for="(item,index) in list" :key="index">
					<a-input v-model:value="item.name"/>
					<a-button class="btn clo3" @click="submit(item.name,index)">保存</a-button>
					<a-popconfirm
					    title="确认是否删除?"
					    ok-text="确认"
					    cancel-text="取消"
					    @confirm="confirm(index)"
					    @cancel="cancel">
					    <a-button type="danger" class="btn">删除</a-button>
					  </a-popconfirm>
				 </div>
				 <div class="add-btn">
				 	<a-button shape="circle" @click="addBtn()">
				 		<template #icon>
				 			<plus-outlined />
				 		</template>
				 	</a-button>
				 </div>
				 <a-button style="margin-left: 220px;" class="btn clo6" @click="goBack()">返回</a-button>
			</div>
		</div>
	</GModule>	
</template>

<script>
	import { message } from 'ant-design-vue';
	export default{
		name:'ProductMaintain',
		data(){
			return{
				list:[],
				breadcrumb:[] // 模块路径
			}
		},
		computed:{
			proId(){
				return this.$store.state.proId
			},
			menuList(){
				return this.$store.state.menuList
			},
			menuUrl(){
				return this.$store.state.menuUrl
			}
		},
		watch:{
			menuUrl(val,oldvalue){
				if(val.length > 0){
					let id = val[val.length-1].id
				}
				
			}
		},
		created(){
			this.$store.commit('setShowBtn',false) // 不显示模块按钮
		},
		methods:{
			goBack(){
				this.$store.commit('setShowBtn',true) // 显示模块按钮
				this.$router.back() // 返回
			},
			addBtn(){
				// 添加行
				this.list.push({name:''})
			},
			confirm(index){
				// 确认删除
				this.list.splice(index,1)
				message.success('删除成功');
			},
			cancel(){
				//取消删除
				message.error('已取消删除');
			},
			async submit(name,index){
				// 保存
				console.log(this.menuList.length ,"想 ",name,index)
				let res = await this.$utils.api.post({
					url:'/modular/add',
					data:{
						'row':{
							'name':name,
							'pid': '', // this.proId
							'weigh':this.menuList.length + 1
						}
					},
					result:1
				})
				console.log("添加结果",res)
				if(res.code == 1){
					this.$utils.msgSuc('添加成功')
					this.list.splice(index,1)
					this.$store.commit('setIsFresh',1) // 刷新菜单列表
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
.container{
	margin: 20px;
	padding: 0;
	overflow-x: hidden;
	.add-box{
		display: flex;
		justify-content: center;
		max-height: 550px;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 0px; // 控制滚动条
		}
		&::-webkit-scrollbar-thumb{
			background-color: #bfbfbf; // 滚动条颜色
		}
		&:hover{
			&::-webkit-scrollbar {
				width: 1px;
			}
		}
		.add{
			width: 500px;
			.child{
				display: flex;
				align-items: center;
				padding-top: 20px;
				.btn{
					margin-left: 20px;
				}
			}
		}
		.add-btn{
			display: flex;
			justify-content: center;
			align-items: center;
			height: 140px;
			.ant-btn-icon-only{
				width: 45px !important;
				height: 45px !important;
				padding:0 !important;
			}
		}
	}
}
</style>